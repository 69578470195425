<template>
  <div class="home">
    <!-- eslint-disable -->
    <div class="legal-holder">
      <h1>End User License Agreement</h1>
      <p>Tuumik Systems OÜ (<strong>"Supplier"</strong>), a company registered in Estonia (registry code 14387706, address Värsi tn 14a, 10919 Tallinn, Estonia), develops a software application for team management that is made commercially available to customers (<strong>"Application"</strong>). This software is described in more detail at www.tuumik.com. This End User License Agreement (<strong>"Agreement"</strong>) stipulates the terms and conditions under which the Supplier grants a customer (<strong>"Customer"</strong>) and its users (<strong>"Users"</strong>) a license to use the Application.</p>
      <p>By using the Application, you are agreeing to be bound by the Agreement. If you do not agree with the Agreement, do not access or use the Application in any way.</p>
      <p>The Supplier's contact data is available at www.tuumik.com.</p>
      <p>In this Agreement "Customer" shall mean any legal or natural person that has caused a deployment of the Application to be set up for its use either by itself, the Supplier or via third parties. And "User" shall mean any person that has received authorized access to the Application via a deployment set up for the Customer.</p>
      <h2>1. Intellectual Property</h2>
      <p>1.1 The Application is proprietary software. All intellectual property related to the Application shall belong to, and is retained by, the Supplier. The Customer shall not acquire any rights thereto in connection with using the Application, except for the limited rights of use expressly set forth in the Agreement.</p>
      <p>1.2 The Supplier has made the Application's source code publicly available on the internet, but the Application remains proprietary software and it is not published under an open source license. Any use of the Application requires a valid license granted by the Supplier.</p>
      <h2>2. Fees and Payment</h2>
      <p>2.1 For use of the Application the Customer shall pay to the Supplier a recurring subscription fee. The amount of such fee shall depend on the subscription plan and quantity selected by the Customer. Prices for different plans are described at www.tuumik.com.</p>
      <p>2.2 Payments are non-refundable. There are no refunds or credits for partial use of the Application.</p>
      <p>2.3 A valid credit card is required for payment of fees.</p>
      <p>2.4 All fees are exclusive of any taxes, levies or duties imposed by taxing authorities, except when otherwise required by mandatory law. Customer is solely responsible for the payment of such taxes, levies or duties.</p>
      <p>2.5 If the Customer's subscription to the Application depends on a billable quantity (for example the number of Users using the Application) and the Application is not configured to update the Customer's subscription with this data automatically, then the Customer shall manually update its subscription with the relevant data at least once per year unless agreed differently between the parties.</p>
      <h2>3. Free Subscription</h2>
      <p>3.1 Subject to the Supplier's sole discretion, the Supplier may stipulate a subscription plan under which the Application can be used free of charge. The conditions of such free subscription plan shall be published on www.tuumik.com.</p>
      <p>3.2 The Supplier may change the conditions of any free subscription plan at any time without prior notice.</p>
      <p>3.3 If a Customer has registered its free use of the Application with the Supplier, then the Customer shall have the right to continue its free use of the Application under the same conditions even if the Supplier discontinues to offer this free subscription plan to new customers.</p>
      <h2>4. License</h2>
      <p>4.1 Subject to the Agreement, and the payment of applicable fees, the Supplier grants the Customer and its Users a non-exclusive, non-transferable, non-sub-licensable license to use the Application during the period that the Customer subscribes to the Application.</p>
      <p>4.2 All intellectual property related to the Application shall belong to, and is retained by, the Supplier. The Customer nor its Users shall not acquire any rights thereto in connection with the Application, except for the limited rights of use expressly set forth in the Agreement.</p>
      <h2>5. Distribution</h2>
      <p>5.1 The Customer may not redistribute the Application to third parties in any form other than by linking to www.tuumik.com and/or github.com/tuumikapp.</p>
      <p>5.2 The Customer may not offer the Application to third parties as a service unless the Supplier has agreed to this in writing.</p>
      <h2>6. Development</h2>
      <p>6.1 The Customer may modify and customize the Application for its own use.</p>
      <p>6.2 The Customer may not remove from the Application any reference to this Agreement or the Supplier. The Customer may not make any modifications to the Application which might cause any user of the Application acting in good will not to understand that the Application is proprietary software and requires a license from the Supplier.</p>
      <h2>7. Changes</h2>
      <p>7.1 The Supplier may, at its sole discretion, modify the structure or prices of any fees applicable to the Customer in relation to the Application or any other condition of a subscription plan at any time and for any reason by giving 30 days of notice, unless agreed differently between the parties.</p>
      <p>7.2 If a Customer does not agree with any changes to any fees or subscription plans, then its sole remedy shall be to terminate its use of the Application.</p>
      <h2>8. Termination</h2>
      <p>8.1 The Customer may terminate its subscription to the Application at any time.</p>
      <p>8.2 If the Customer is in breach of the Agreement, then the Supplier may terminate or suspend the Customer's subscription to the Application.</p>
      <h2>9. No Warranty</h2>
      <p>9.1 The Application are provided "as is" and "as available" without warranties of any kind either express or implied. To the fullest extent permissible pursuant to applicable law, the Supplier disclaims all warranties, statutory, express or implied, including, but not limited to, implied warranties of merchant ability, fitness for a particular purpose, non-infringement of proprietary rights, correctness, accuracy, and reliability.</p>
      <p>9.2 The Supplier in particular makes no representation and gives no warranty or guarantee: (a) that the Application is fit for any particular purpose, accurate, timely, of satisfactory quality, available regardless of, or in any specific, jurisdiction, or non-infringing of third-party rights; (b) that access to or the operation or use of the Application will be uninterrupted, secure or error-free; (c) that any error or defect in the Application will be corrected; (d) that the Application or any means by which it is accessed or used are free of malware or other harmful components.</p>
      <h2>10. Limitation of Liability</h2>
      <p>10.1 The Supplier shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses, resulting from: (a) the use of or the inability to use the Application; (b) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the Application; (c) unauthorized access to or alteration of Customer's transmissions or data; (d) statements or conduct of any third party on the Application; (e) or any other matter relating to the Application.</p>
      <p>10.2 In no event shall the aggregate liability of the Supplier arising out of or related to the Agreement exceed the total amount paid by the Customer hereunder for a subscription to the Application giving rise to the liability in the six months preceding the first incident out of which the liability arose.</p>
      <h2>11. Personal Data</h2>
      <p>11.1 The Provider's Privacy Policy, available at www.tuumik.com/legal/privacy-policy, is hereby incorporated into the Agreement by reference. The Privacy Policy governs how the Provider processes any personal data in connection with the Application.</p>
      <h2>12. Governing Law and Jurisdiction</h2>
      <p>12.1 The Agreement shall be governed by the laws of the Republic of Estonia.</p>
      <p>12.2 Any dispute arising out of the Agreement shall be resolved in Harju County Court in Estonia.</p>
      <p class="update-date">Last update: December 8, 2024</p>
    </div>
    <!-- eslint-enable -->
  </div>
</template>

<script>
export default {
  name: 'TermsOfService',
  mounted() {
    document.body.scrollIntoView({ behavior: 'instant' });
  },
};
</script>

<style scoped>
.home {
  padding: 3em 0 1em 0;
}

.legal-holder {
  margin: 6em 0;
  padding: 1em;
  text-align: justify;
  background-color: #ffffff;
  color: #000000;
  border-radius: 0.3em;
  border: 1px solid #cecece;
  box-shadow: 0 0 0.9em 0 rgba(0, 0, 0, 0.07);
}

.legal-holder h1 {
  font-size: 2.1em;
  text-align: center;
  margin: 1em 0 2em 0;
}

.legal-holder h2 {
  font-weight: 600;
  font-size: 1.2em;
  margin: 1em 0;
}

.update-date {
  color: #7f7f7f;
  margin: 3em 0 0 0;
}

@media only screen and (min-width: 768px) {
  .legal-holder {
    margin: 6em auto;
    padding: 2em 3em;
    max-width: 50em;
  }
}
</style>
